import { createHttpEndpoint } from '../../utils'

/**
 * Get details about merchant shop
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/merchant-shop-api/definition#tag/merchantShop/operation/getMerchantShop}
 */
export const getMerchantShop = createHttpEndpoint<{ company: string }>({
  method: 'GET',
  operationId: 'getMerchantShop',
  path: '/bm/merchant/shop/:id',
  transformResponseToCamelCase: true,
})
