<template>
  <section>
    <h2 class="body-1-bold">
      {{ i18n(translations.filterByStars) }}
    </h2>
    <ul>
      <RevListItemStatic
        v-for="range in rangesList"
        :key="range.label"
        class="-mb-16"
        role="radioGroup"
      >
        <template #label>
          <div
            :class="[
              'flex items-center justify-between',
              {
                'cursor-pointer': !range.disabled,
                'cursor-not-allowed': range.disabled,
              },
            ]"
            role="button"
            tabindex="-1"
            @click.prevent="handleRangeClick(range)"
            @keydown="handleRangeClick(range)"
          >
            <RevRadio
              :id="range.selected"
              class="w-1/4 md:w-1/3"
              :disabled="range.disabled"
              :model-value="selectedRange === range.selected"
              name="rateFilters"
              :value="range.selected"
            >
              <template #label>
                <div class="body-1">{{ range.label }}</div>
              </template>
            </RevRadio>

            <div class="flex w-4/5 items-center justify-between md:w-2/3">
              <ProgressBar
                class="mx-2 grow"
                :current-value="range.value"
                :is-loading="loading"
              />

              <div class="body-1 w-1/3 text-right">
                {{
                  i18n(translations.percentage, {
                    percentage: range.value,
                  })
                }}
              </div>
            </div>
          </div>
        </template>
      </RevListItemStatic>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevRadio } from '@ds/components/Radio'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'

import { TRACKING_ZONE } from '../../constants'
import ProgressBar from '../ProgressBar/ProgressBar.vue'

import translations from './Filters.translations'
import { useRatingFilters } from './composables/useRatingFilters'

const route = useRoute()
const router = useRouter()
const i18n = useI18n()

const { trackClick } = useTracking()

const { selectedRange, rangesList, loading } = useRatingFilters()

type Range = {
  disabled: boolean
  label: string
  selected: string
  value: number
  min: number
  max: number
}

function handleRangeClick({ selected, disabled, min, max }: Range) {
  if (!disabled && selectedRange.value !== selected) {
    selectedRange.value = selected

    const routeName = route.name?.toString() || ''

    trackClick({
      pageType: TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName],
      zone: TRACKING_ZONE,
      name: `review_star_filter_between_${min}_and_${max}`,
    })

    router.push({
      ...route,
      // I don't know why the spread isn't keeping the hash, but without it we'll lose it, which is problematic on the product page
      // as we heavily rely on it for the current grade / new battery state.
      hash: route.hash,
      query: {
        ...route.query,
        global_rate__gte: min,
        global_rate__lte: max,
      },
    })
  }
}
</script>
