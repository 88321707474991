export default {
  sortBy: {
    id: 'reviews_sort_title',
    defaultMessage: 'Sort by',
  },
  rateAscending: {
    id: 'review_display_rate_ascending',
    defaultMessage: 'The lowest rate',
  },
  rateDescending: {
    id: 'review_display_rate_descending',
    defaultMessage: 'The highest rate',
  },
  recentReviews: {
    id: 'review_display_recent_reviews',
    defaultMessage: 'Most recent reviews',
  },
  relevance: {
    id: 'review_display_relevance',
    defaultMessage: 'Relevance',
  },
}
