<template>
  <div :data-selector="SEO_TEXT_DATA_SELECTOR_ATTRIBUTE_FOR_SEO">
    <p class="body-1">
      {{ i18n(translations.seoText) }}
    </p>
    <FormattedMessage
      id="collapsibleText"
      :class="[
        'body-1 block',
        {
          hidden: isCollapsed,
        },
      ]"
      :definition="translations.seoTextHiddenPart"
    >
      <template #link>
        <RevLink
          class="block py-8"
          target="_blank"
          :to="resolveLocalizedRoute({ name: ABOUT_US })"
        >
          {{ i18n(translations.aboutUs) }}
        </RevLink>
      </template>
    </FormattedMessage>

    <RevLink
      aria-controls="collapsibleText"
      :aria-expanded="!isCollapsed"
      class="mt-8 p-0 pb-24 md:pb-0"
      @click="toggleCollapsedState"
    >
      {{ i18n(toggleLabel) }}
    </RevLink>
  </div>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'
import { computed, ref } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import { ABOUT_US } from '~/scopes/about-us/routes.constants'

import translations from './SeoText.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const SEO_TEXT_DATA_SELECTOR_ATTRIBUTE_FOR_SEO = 'reviews-text'
const isCollapsed = ref(true)

const toggleLabel = computed(() =>
  isCollapsed.value ? translations.seeMore : translations.seeLess,
)

function toggleCollapsedState() {
  isCollapsed.value = !isCollapsed.value
}
</script>
