import { useRoute } from '#imports'
import { ref, watch } from 'vue'

import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { ROUTES } from '../../route-names'

import { useRequests } from './useRequests'

function useFetchSeoTags() {
  const route = useRoute()
  const routeName = route.name?.toString() ?? ''

  const { getSeoTags = undefined } = { ...useRequests() }
  const tagModelByRoute = {
    [ROUTES.PAGES.PRODUCT]: 'product',
    [ROUTES.PAGES.LANDING]: 'landing',
  }

  if (!getSeoTags || !tagModelByRoute[routeName]) {
    return { data: ref(), error: ref() }
  }

  return useHttpFetch(getSeoTags, {
    queryParams: { model: tagModelByRoute[routeName], pk: route.params.uuid },
  })
}

export function useSeoTags() {
  const route = useRoute()
  const logger = useLogger()

  const { data: seoTagsResponse, error } = useFetchSeoTags()

  watch(
    error,
    () => {
      if (error.value instanceof Error) {
        logger.error(`[REVIEWS][${route.name?.toString()}]`, {
          error: error.value,
        })
      }
    },
    { immediate: true },
  )

  return seoTagsResponse
}
