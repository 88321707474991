<template>
  <RevHorizontalSeriesBlock size="small">
    <RevHorizontalSeriesBlockItem
      v-for="item in items"
      :key="item.id"
      :data-test="item.id"
      :icon="item.icon"
      :image-src="item.imageSrc"
    >
      {{ item.text }}
    </RevHorizontalSeriesBlockItem>
  </RevHorizontalSeriesBlock>
</template>

<script lang="ts" setup>
import { type Component, computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevHorizontalSeriesBlock } from '@ds/components/HorizontalSeriesBlock'
import { RevHorizontalSeriesBlockItem } from '@ds/components/HorizontalSeriesBlockItem'
import { IconHeadset } from '@ds/icons/IconHeadset'
import { IconQuality } from '@ds/icons/IconQuality'
import { IconRefund } from '@ds/icons/IconRefund'
import { IconTruck } from '@ds/icons/IconTruck'

import type { Features } from '~/features'

import translations from './TrustList.translations'

const i18n = useI18n()

const { features } = useMarketplace<Features>()
const { coolingOffDays } = features

type ReassuranceItem = {
  id: string
  icon?: Component
  text: string
  imageSrc?: string
}
const items = computed<ReassuranceItem[]>(() => [
  {
    id: 'reassurance_warranty',
    icon: IconQuality,
    text: i18n(translations.warranty),
  },
  {
    id: 'reassurance_delivery',
    icon: IconTruck,
    text: i18n(translations.delivery),
  },
  {
    id: 'reassurance_satisfaction',
    icon: IconRefund,
    text: i18n(translations.satisfaction, { days: coolingOffDays }),
  },
  {
    id: 'reassurance_customer_service',
    icon: IconHeadset,
    text: i18n(translations.customerService),
  },
])
</script>
