<template>
  <div ref="reviewsBlockObserverTarget">
    <h2 class="heading-3">
      {{ i18n(translations.title, { title: productTitle }) }}
    </h2>
    <MainRating :rating="rating" />

    <LegalModal />

    <div class="grid-standard mt-24 pt-32 md:gap-56">
      <aside class="md:col-span-4 lg:col-span-3">
        <Filters class="hidden md:block" />
      </aside>
      <div
        class="flex flex-col md:col-span-4 lg:col-span-9"
        data-test="reviews-list"
      >
        <ReviewsList
          :highlighted-review="highlightedReview"
          :highlighted-review-pending="highlightedReviewPending"
          :is-pending="reviewsPending"
          :reviews="reviews"
        />

        <RevButton
          v-if="displayLink"
          class="mt-32 self-start"
          data-qa="see-all-reviews"
          full-width="responsive"
          :to="link"
          :tracking="linkTrackingInfo"
          variant="secondary"
        >
          {{ i18n(translations.cta, { count: i18n.number(rating.count) }) }}
        </RevButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed, onBeforeUnmount, ref, watch } from 'vue'

import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import type { Review } from '@backmarket/http-api/src/api-specs-reviews/types/review'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import type { Location } from '@ds/types/Location'
import { useIntersectionObserver } from '@vueuse/core'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'

import { TRACKING_ZONE } from '../../constants'
import Filters from '../Filters/Filters.vue'
import MainRating from '../MainRating/MainRating.vue'
import LegalModal from '../ReviewsLegalModal/ReviewsLegalModal.vue'
import ReviewsList from '../ReviewsList/ReviewsList.vue'

import translations from './ReviewsBlock.translations'

type ReviewsBlock = {
  productTitle: string
  reviews: Review[]
  reviewsPending?: boolean
  rating: Rating
  link: Location
  trackingInfo?: Record<string, unknown>
  highlightedReview?: Review
  highlightedReviewPending?: boolean
}

const props = withDefaults(defineProps<ReviewsBlock>(), {
  trackingInfo: () => ({}),
  highlightedReview: undefined,
  highlightedReviewPending: false,
})

const route = useRoute()
const i18n = useI18n()
const { trackReviewBlockImpression } = useTracking()

const reviewsBlockObserverTarget = ref<null | HTMLElement>(null)
const hasTriggeredImpression = ref(false)

const displayLink = computed(() => props.rating.count > 1)

const routeName = route.name?.toString() || ''
const pageType = TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName]

const linkTrackingInfo = computed(() => ({
  zone: TRACKING_ZONE,
  name: 'see_all_reviews',
  pageType,
}))

const blockTrackingInfo = computed(() => ({
  ...props.trackingInfo,
  pageType,
  averageRate: props.rating.averageRate,
}))

const { stop } = useIntersectionObserver(
  reviewsBlockObserverTarget,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      trackReviewBlockImpression(blockTrackingInfo.value)
      hasTriggeredImpression.value = true
    }
  },
)

watch(hasTriggeredImpression, () => {
  if (hasTriggeredImpression.value) {
    stop()
  }
})

onBeforeUnmount(() => {
  stop()
})
</script>
