<template>
  <RevList :has-external-borders="false">
    <li
      v-for="item in placeholderList"
      :key="item"
      :class="['py-20', { 'pt-0': item === 0 }]"
      data-test="skeleton-user-comment"
    >
      <div class="flex flex-col items-start justify-between gap-32 md:flex-row">
        <div class="flex w-full items-start">
          <RevSkeleton
            class="mr-12 shrink-0"
            height="4rem"
            shape="circle"
            width="4rem"
          />

          <div class="flex-1">
            <p>
              <RevSkeleton shape="line" width="5rem" />
            </p>

            <p class="mt-4">
              <RevSkeleton shape="line" />
            </p>
          </div>
        </div>
        <RevSkeleton shape="line" width="10rem" />
      </div>
      <div class="mt-32">
        <CommentSkeleton />
      </div>
      <RevSkeleton class="mt-32 block" shape="line" width="15rem" />
      <RevSkeleton class="mt-32" shape="line" width="10rem" />
    </li>
  </RevList>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { RevList } from '@ds/components/List'
import { RevSkeleton } from '@ds/components/Skeleton'

import CommentSkeleton from '../CommentSkeleton/CommentSkeleton.vue'

const props = defineProps<{ limit: number }>()

const placeholderList = computed(() => Array.from(Array(props.limit)))
</script>
