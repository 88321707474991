export default {
  // SEO title
  SEOTitleAllProducts: {
    id: 'review_display_all_reviews_meta_title',
    defaultMessage:
      'Is Back Market reputable? Read reviews for all our refurbished electronics',
  },
  SEOTitleListingProduct: {
    id: 'review_display_landing_meta_title_new',
    defaultMessage: 'Customer reviews for all refurbished {productName}',
  },
  SEOTitleMerchantProducts: {
    id: 'review_display_merchant_meta_title_new',
    defaultMessage: '{merchantName} Customer Reviews',
  },
  SEOTitleProduct: {
    id: 'review_display_product_meta_title_new',
    defaultMessage: 'Customer reviews for a specific item - {productName}',
  },

  // SEO description
  SEODescriptionAllProducts: {
    id: 'review_display_all_reviews_meta_description_new',
    defaultMessage:
      'Wondering if Back Market services are reliable? Are prices a scam? Overall rating is {rate} out of {count} reviews. Read all of our customer reviews.',
  },
  SEODescriptionListingProduct: {
    id: 'review_display_landing_meta_description_new',
    defaultMessage:
      'Wondering if {productName} sold on Back Market are reliable? Is there a scam? Our overall rating is {rate} out of {count} reviews. Read all of our customer reviews.',
  },
  SEODescriptionMerchantProducts: {
    id: 'review_display_merchant_meta_description_new',
    defaultMessage:
      'Wondering if a seller on Back Market - {merchantName} - is reliable? Are their refurbished electronics of good quality? Our overall rating is {rate} out of {count} reviews. Read all of our customer reviews.',
  },
  SEODescriptionProduct: {
    id: 'review_display_product_meta_description_new',
    defaultMessage:
      'Wondering if the item called {productName} - is reliable? Our overall rating is {rate} out of {count} reviews. Read all of our customer reviews.',
  },
} as const
