export default {
  breadcrumb: {
    id: 'breadcrumb_aria_label',
    defaultMessage: 'Breadcrumb',
  },
  loadMore: {
    id: 'review_display_loadmore_reviews',
    defaultMessage: 'Show more reviews',
  },
} as const
