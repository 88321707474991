export default {
  // breadcrumb label
  breadcrumbHomePage: {
    id: 'reviews_page_breadcrumb_home',
    defaultMessage: 'Home',
  },
  breadcrumbCurrentPage: {
    id: 'reviews_page_breadcrumb_current_page',
    defaultMessage: 'Reviews',
  },

  // back button label
  goBackProduct: {
    id: 'review_display_goback_product',
    defaultMessage: 'Go back to the device',
  },

  goBackLanding: {
    id: 'review_display_goback_landing',
    defaultMessage: 'Go back to the deals',
  },

  goBackSeller: {
    id: 'review_display_goback_seller',
    defaultMessage: 'Go back to the seller',
  },

  goBackDefault: {
    id: 'review_display_goback_default',
    defaultMessage: 'Home',
  },
} as const
