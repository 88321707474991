import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './Sort.translations'
import type { RateSortOption } from './Sort.types'

const SORT_OPTIONS = {
  RELEVANCE_DESC: '-relevance',
  CREATED_AT_DESC: '-created_at',
  GLOBAL_RATE_DESC: '-global_rate',
  GLOBAL_RATE_ASC: 'global_rate',
} satisfies Record<string, RateSortOption>

export function getSortOptions() {
  const i18n = useI18n()

  const list: Array<{
    label: string
    value: RateSortOption
  }> = [
    {
      label: i18n(translations.relevance),
      value: SORT_OPTIONS.RELEVANCE_DESC,
    },
    {
      label: i18n(translations.recentReviews),
      value: SORT_OPTIONS.CREATED_AT_DESC,
    },
    {
      label: i18n(translations.rateDescending),
      value: SORT_OPTIONS.GLOBAL_RATE_DESC,
    },
    {
      label: i18n(translations.rateAscending),
      value: SORT_OPTIONS.GLOBAL_RATE_ASC,
    },
  ]

  return {
    list,
    defaultOption: SORT_OPTIONS.RELEVANCE_DESC,
  }
}
