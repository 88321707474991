export default {
  errorToastMessage: {
    id: 'translation_error_message',
    defaultMessage:
      'Our server is a bit slow at the moment. Please give it a sec and try again.',
  },
  errorToastTitle: {
    id: 'translation_error_title',
    defaultMessage: 'Oops, something went wrong.',
  },
} as const
