<template>
  <UserCommentModal :pictures="carouselImages" :review="review">
    <template #trigger="{ open }">
      <RevCardCarousel
        class="mt-32"
        desktop-card-width="narrow"
        mobile-card-width="narrow"
        :next-alternative-text="i18n(translations.alternativeNext)"
        :prev-alternative-text="i18n(translations.alternativePrevious)"
        size="extra-small"
      >
        <RevButtonBase
          v-for="photo in attachments"
          :key="photo.uploadId"
          class="!h-full"
          :tracking="clickTrackingData"
          @click="open"
        >
          <RevIllustration
            alt=""
            class="rounded-md !h-[160px] object-cover"
            :height="160"
            sizes="160px"
            :src="photo.url"
            :width="160"
          />
        </RevButtonBase>
      </RevCardCarousel>
    </template>
  </UserCommentModal>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import UserCommentModal from '@backmarket/nuxt-layer-reviews/review-display/UserCommentModal.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { omit } from '@backmarket/utils/object/omit'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevIllustration } from '@ds/components/Illustration'

import type { DisplayedReview } from '../../models/reviews'

import translations from './PhotosSlider.translations'

const props = defineProps<
  DisplayedReview & {
    position: number
    isHighlighted?: boolean
    trackingData: Record<string, unknown>
  }
>()

const i18n = useI18n()

const clickTrackingData = {
  ...props.trackingData,
  name: 'review_photo',
  review_nb_photos: props.attachments?.length || 0,
}

const carouselImages = computed(() => {
  return props.attachments?.map(({ url }) => url) ?? []
})

const review = computed(() => ({
  ...omit(props, ['position', 'isHighlighted']),
  reviewDate: props.createdAt,
  purchaseDate: props.product.purchasedAt,
}))
</script>
