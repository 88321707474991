<template>
  <div class="mt-12 flex flex-wrap items-center">
    <RevRating class="mr-12" :score="rating.averageRate" size="medium" />
    <div class="mt-12 flex sm:items-center lg:mt-0" data-test="rating">
      <IconQuality class="mt-2" size="small" />
      <p class="body-2 ml-4 block lg:body-1">
        {{
          i18n(translations.rateDescription, {
            totalReviews: rating.count,
          })
        }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevRating } from '@ds/components/Rating'
import { IconQuality } from '@ds/icons/IconQuality'

import translations from './MainRating.translations'

defineProps<{ rating: Rating }>()

const i18n = useI18n()
</script>
