export default {
  title: {
    id: 'product_reviews_block_bottom_title',
    defaultMessage: "{ title }'s customer reviews",
  },
  cta: {
    id: 'product_reviews_block_button_v2',
    defaultMessage: 'See all { count } reviews',
  },
}
