import { useRoute, useRouteLocationWithLocale } from '#imports'
import { type Ref, computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { insertIf } from '@backmarket/utils/collection/insertIf'

import { HOME } from '~/scopes/home/route-names'
import { PRODUCT } from '~/scopes/product/route-names'
import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import { ROUTES } from '../../route-names'
import translations from '../translations/reviewsNavigation.translations'

export function useReviewsNavigation(shortTitle: Ref<string | null>) {
  const route = useRoute()
  const i18n = useI18n()

  const resolveLocalizedRoute = useRouteLocationWithLocale()

  const routeName = route.name?.toString() || ''

  const previousPageByRoute = {
    [ROUTES.PAGES.PRODUCT]: {
      label: i18n(translations.goBackProduct),
      name: PRODUCT.HOME,
    },

    [ROUTES.PAGES.MERCHANT]: {
      label: i18n(translations.goBackSeller),
      name: PRODUCT_LIST_ROUTES.SELLER_PRODUCT_LIST,
    },

    [ROUTES.PAGES.LANDING]: {
      label: i18n(translations.goBackLanding),
      name: PRODUCT_LIST_ROUTES.PRODUCT_LIST,
    },

    [ROUTES.PAGES.ALL]: { label: i18n(translations.goBackDefault), name: HOME },
  }

  const { name, label } = previousPageByRoute[routeName] || {}

  const ancestorLink = resolveLocalizedRoute({
    name,
    params: route.params,
  })

  const breadcrumb = computed(() => [
    {
      link: resolveLocalizedRoute({ name: HOME }),
      title: i18n(translations.breadcrumbHomePage),
    },
    ...insertIf(!!shortTitle.value, [
      {
        link: ancestorLink,
        title: shortTitle.value || '',
      },
    ]),
    {
      link: route.path,
      title: i18n(translations.breadcrumbCurrentPage),
    },
  ])

  return {
    breadcrumb,
    backButton: {
      link: ancestorLink,
      label,
    },
  }
}
