export default {
  alternativeController: {
    id: 'review_photo_slider_alternative_controller',
    defaultMessage: 'Controller',
  },
  alternativeNext: {
    id: 'review_photo_slider_next_slide',
    defaultMessage: 'Next photo',
  },
  alternativePrevious: {
    id: 'review_photo_slider_prev_slide',
    defaultMessage: 'Previous photo',
  },
  alternativeClose: {
    id: 'review_photo_slider_alternative_close',
    defaultMessage: 'Close',
  },
} as const
