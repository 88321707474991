export default {
  seoText: {
    id: 'review_display_seo_text',
    defaultMessage:
      "Is Back Market a reliable website? Can Back Market be trusted? Are the items sold of good quality? What is the meaning of life? Are we alone in the universe? What is the airspeed velocity of an unladen swallow? So many questions so little time. But we'll try to answer (at least the vital ones) here.",
  },
  seoTextHiddenPart: {
    id: 'review_display_seo_text_hidden_part',
    defaultMessage:
      "Back Market is a marketplace. By definition, we connect sellers with end customers via our website. The quality of the refurbished items sold on Back Market is therefore dependent on the quality of the sellers themselves. Our goal is to ensure that they always provide an incredible level of service. To achieve this, we subject all high-tech device refurbishing plants to our most stringent selection processes, which takes into account all relevant criteria: quality of diagnosis, responsiveness of customer service, delivery times, etc. But that's not enough: our sales partners must also comply with a set of very strict specifications before they can sell on our website. As a result, we're able to guarantee that the refurbished smartphones, laptops, and other items that you buy from us will be just as functional as a unit sold new. But you don't have to just take our word for it. Rather, go and see for yourself how our main partners are giving used electronic items a second life. {link, html} If you still need a little more convincing, don't hesitate to check out our other customer reviews in the section below. For any other questions... Google it.",
  },
  aboutUs: {
    id: 'review_display_about_us',
    defaultMessage: 'About Us',
  },
  seeMore: {
    id: 'review_page_see_more',
    defaultMessage: 'See more',
  },
  seeLess: {
    id: 'review_page_see_less',
    defaultMessage: 'See less',
  },
}
