export default {
  modalTrigger: {
    id: 'review_legal_modal_trigger',
    defaultMessage: 'How customer reviews work',
  },
  modalTitle: {
    id: 'review_legal_modal_title',
    defaultMessage: 'How customer reviews work',
  },
  modalOrderExplained: {
    id: 'review_legal_modal_text_order_explained',
    defaultMessage:
      'Reviews are listed in chronological order, from most recent to least recent.',
  },
  modalWhoComments: {
    id: 'review_legal_modal_text_who_leaves_comments',
    defaultMessage:
      'Only customers who have purchased the concerned item on Back Market can share their review. We publish both positive and negative reviews without moderation before publication. After publication, any disparaging, insulting or abusive review may, upon request of any interested party, be removed by Back Market.  Customers can give a rating from 1 to 5, and add a comment to their rating. For some products, they can give specific ratings (quality/price ratio, delivery, packaging and cleanliness, overall performance, aesthetic aspect): in this case, the overall rating of the review (out of 5) is an average of the specific ratings.',
  },
  legalLink: {
    id: 'review_legal_modal_link_learn_more',
    defaultMessage: 'Learn more',
  },
}
