export default {
  filterByStars: {
    id: 'reviews_rate_filter_title',
    defaultMessage: 'Filter by stars',
  },
  all: {
    id: 'reviews_rate_filter_all',
    defaultMessage: 'All',
  },
  fourFive: {
    id: 'reviews_rate_filter_between_4_and_5',
    defaultMessage: '4-5 stars',
  },
  threeFour: {
    id: 'reviews_rate_filter_between_3_and_4',
    defaultMessage: '3-4 stars',
  },
  twoThree: {
    id: 'reviews_rate_filter_between_2_and_3',
    defaultMessage: '2-3 stars',
  },
  oneTwo: {
    id: 'reviews_rate_filter_between_1_and_2',
    defaultMessage: '1-2 stars',
  },
  percentage: {
    id: 'reviews_rate_filter_percentage',
    defaultMessage: '{ percentage }%',
  },
}
