export default {
  customerService: {
    id: 'reassurance_items_customer_service',
    defaultMessage: 'Friendly customer service',
  },
  delivery: {
    id: 'reassurance_items_shipping',
    defaultMessage: 'Free standard shipping',
  },
  satisfaction: {
    id: 'reassurance_items_return',
    defaultMessage: 'Free {days}-day returns',
  },
  warranty: {
    id: 'reassurance_items_warranty',
    defaultMessage: '1-year warranty',
  },
}
