<template>
  <div aria-hidden="true" class="relative">
    <div
      class="border-t-static-default-low rounded-xs absolute bottom-0 h-3 w-full border-t-3"
    >
      <div
        v-if="isLoading"
        class="bg-action-default-hi rounded-sm absolute -top-3 h-3 w-1 opacity-20"
        :class="$style.loading"
      />
      <div
        class="border-t-static-default-hi rounded-sm absolute -top-3 border-t-3 ease-in-out motion-safe:transition-all motion-safe:duration-200"
        :style="progressStyle"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    currentValue?: number
    max?: number
    isLoading?: boolean
  }>(),
  { currentValue: 0, max: 100, isLoading: false },
)

const progressStyle = computed(() => ({
  width: `${
    100 * Math.max(Math.min(props.currentValue, props.max) / props.max, 0)
  }%`,
}))
</script>

<style module>
.loading {
  animation: indicator 3s linear infinite;
}

@keyframes indicator {
  0% {
    width: 0%;
    left: 0%;
  }

  75% {
    width: 50%;
    left: 50%;
  }

  100% {
    width: 0%;
    left: 100%;
  }
}
</style>
