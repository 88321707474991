import type { Review } from '@backmarket/http-api/src/api-specs-reviews/types/review'

import type { DisplayedReview } from '../models/reviews'

export const transformReviewsResponse = (
  results: Review[],
): DisplayedReview[] =>
  results.map((review) => ({
    ...review,
    attachments: review.details?.find(
      (detail) => detail.identifier === 'review_attachments',
    )?.attachments,
  }))
